import React, { useState, useEffect } from 'react'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'

import {
  Breadcrumb,
  BreadcrumbItem,
  ContentArea,
  PageTitle,
  SpecificContactForm,
  TitleBorder,
  VerticalCard,
  VerticalCardBody
} from '@bscs-dev-team/bscs-design-system-core'

import Layout from '../../components/layout'
import SEO from '../../components/seo'

// eslint-disable-next-line
import rowImage from '../../queries/images/row-image'


const SixtiethAnniversaryPage = (props) => {
  return (
    // <Layout id="sixty-page-layout-wrapper" location={props.location}>
    <Layout>
      <SEO
        title="60 Years of Stories"
        description="BSCS shares stories of science educators, leaders, and longtime supporters."
        canonicalUrl="https://bscs.org/about/60th-anniversary/"
      />
      <div>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link className='inline-anchor' to="/">Home</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            About
          </BreadcrumbItem>
          <BreadcrumbItem>
            60 Years of Stories
          </BreadcrumbItem>
        </Breadcrumb>
        <PageTitle>BSCS Science Learning Celebrates 60th Anniversary in 2018</PageTitle>
        <TitleBorder />
        <div className="flex flex-wrap-reverse">
          <div className="w-full lg:w-2/3 xl:w-3/4">
            <section>
              <p>For 60 years, BSCS Science Learning has worked diligently to transform science education. We have grown from a single curriculum development project into an independent nonprofit, revered today as an innovative leader in instructional materials development, teacher professional learning, leadership development, and research.</p>

              <p>How do we know we've made an impact? For starters, you've told us. For every story we shared in 2018 about a dedicated educator, scientist, or partner who has contributed to our mission— we received multiple stories in return from individuals whose lives and careers have been shaped by BSCS.</p>

              <p>See below for a selection of these stories…</p>
            </section>
          </div>
          <div className="w-full lg:w-1/3 xl:w-1/4 p-2">
            <VerticalCard>
              <Img
                alt="60 Years of Stories collage"
                className="h-full"
                fluid={props.data.sixty_years_of_stories.childImageSharp.fluid}
              />
              <VerticalCardBody>
                <p>
                  Do you have a story you want to share?
                </p>
                <div className="flex justify-center mt-2">
                  <SpecificContactForm person="Lauren Novo" infoat={true} />
                </div>
              </VerticalCardBody>
            </VerticalCard>
          </div>
        </div>
        {/* <div  id="sixty-page-container" className="flex flex-wrap-reverse" style={{ marginBottom: '2rem'}}> */}
        <div className='mt-3'>
            <section id="katherine-and-randall-backe-story" className="sixty-section rounded-top">
              <ContentArea size='xl'>
                <div className="flex w-full flex-wrap sixty-title-row items-end">
                  <h2 className="mx-4 mt-4 mb-0 sixty-title">Kathrine and Randall Backe</h2>
                  {/*<div className="ml-0 ml-sm-auto">
                    <Img
                      className="flex mt-4 mx-4 mb-0 rounded"
                      fixed={props.data.susan_udin.childImageSharp.fixed}
                      alt="young boy with glasses and blue hoodie scrunching his face while looking at a vial in his right hand"
                    />
                  </div> */}
                </div>
                <hr className="mx-4" />

                <div className="my-2 px-4 py-0">
                  <p>Kathrine Backe was invited to join BSCS in 1991 as implementation coordinator for the field test phase of Middle School Science and Technology. She developed an implementation guide and made on-site visits to field test schools. During that time, she also was working on her dissertation, incorporating much of that BSCS experience. Meanwhile, her husband Randall was teaching at a private liberal arts college in Wisconsin while also working on his dissertation. Both Kathrine and Randall were completing Ph.D. programs at Kansas State University. It was there that both found opportunities to work on Middle School Science and Technology and utilize it as part of their doctoral studies. Randall credits Kathrine with taking that mid-career leap and inviting him to join her at Kansas State, and later at BSCS.</p>

                  <p>In the early 1990s, Kathrine built teacher development modules, incorporating videodisc technology and print modules designed to support and improve science teaching in elementary schools (Decisions in Teaching Elementary School Science). She most enjoyed working with teachers and students across the country while implementing the field test version of Middle School Science and Technology.</p>

                  <p>Randall arrived at BSCS in 1992, collaborating on projects such as the first edition of Biology: A Human Approach. Later in that decade, one of his most rewarding projects (co-authored with David Hanych) was The Commons: An Environmental Dilemma, a CD-ROM and Implementation Guide that accompanied the college textbook Biological Perspectives.</p>

                  <p>Kathrine and Randall treasured their time at BSCS during the 1990s: a time when they, along with their BSCS colleagues, shared a dedication to enhancing science education.</p>
                </div>
              </ContentArea>
            </section>
            <section id="james-barufaldi-story">
              <ContentArea size='xl'>
                <div className="flex w-full flex-wrap sixty-title-row items-end">
                  <h2 className="mx-4 mt-4 mb-0 sixty-title">James Barufaldi</h2>
                  <div className="ml-0 ml-sm-auto">
                    <Img
                      className="flex mt-4 mx-4 mb-0 rounded"
                      fixed={props.data.james_barufaldi.childImageSharp.fixed}
                      alt="James Barufaldi"
                    />
                  </div>
                </div>
                <hr className="mx-4 sixty-hr-bscs-blue" />

                <div className="px-4 py-2 sixty-content-alt">
                  <p>Teaching high school biology in the 1960s in the Cleveland, Ohio public schools was my first experience with the innovative curriculum developed by BSCS. I was excited to introduce my students at the John F. Kennedy High School to an inquiry-based biology program, BSCS Biology-yellow version. Working with a very limited budget, it was amazing that my students and I conducted all 39 laboratory activities in the program! After teaching for about 10 years in Cleveland and obtaining both masters and doctoral degrees, I accepted a position at The University of Texas at Austin. I enthusiastically accepted the position because I knew I would become a colleague of Dr. Addison E. Lee, former BSCS Chairman of the Board. From that point on, my career jump-started because of Addison and BSCS. In 1972, Addison mentioned that BSCS was recruiting biology/science educators who had good writing skills in addition to public school experiences to become involved in an elementary school science program. I immediately told Addison I was interested. He notified BSCS, and after a week or so, I was sent a seriously flawed manuscript to edit which happened to be a draft of a BSCS Biology yellow version chapter. The draft was absolutely awful, with inconsistencies and numerous errors. I returned the manuscript with my very critical edited version to BSCS and mentioned to my family that I will never be invited to join the BSCS writing team. But, as they say, "the rest is history."</p>

                  <p>I spent each summer from 1973-1980 writing and consulting at BSCS. Having the opportunity to live in Boulder during the summer months was definitely a plus. In 1976, I spent a sabbatical year at BSCS. Serving as a staff member of the Elementary School Sciences Program provided me with opportunities to write and develop curriculum, present at a national conference, and travel to visit various field test centers. Each summer, BSCS invited talented teachers and professors to assist with the writing of the elementary program. Teachers and professors were from schools across the country and included educators such as Bill Aho, Don Daugs, Harriet Brookman, Mary Sue Kerner, James Wailes, Phyllis Perry, Vickie Larsen, and others, who helped to develop an educationally and scientifically sound, inquirybased elementary school science program. It was an unbelievably rewarding experience especially working with professional educators and staff members such as Rich Tolman, Bill Mayer, Nancy Landes, Rodger Bybee and artists Roy Udo, John Evans, among others.</p>

                  <p>Throughout my time at BSCS I had opportunities to serve as one of the writers for Me in the Future Program, to serve on the BSCS advisory board for Science for Life and Living: Integrating Science, Technology, and Health and to serve as a writer for the NSF funded high school biology program, Biology: A Community Context. In addition, I had the opportunity to co-author research articles with Bill Mayer and Rich Tolman. I am quite proud that I introduced Texas teachers to the BSCS philosophy and programs. In the early 1990s, through a small grant from the Texas Education agency, more than 40,000 teachers were introduced to the BSCS 5E Instructional Model. BSCS greatly influenced both my teaching and research. My undergraduate courses were structured and presented around the BSCS 5E Instructional Model. My first doctoral student at The University of Texas at Austin, Jennifer Swift, wrote her dissertation, entitled The Influence of the BSCS-Elementary School Sciences Program Instruction on First Grade Students Listening Skills based on the BSCS program. Needless to say, working with professional and talented educators at BSCS certainly enriched my career and life. I am forever grateful to BSCS for providing me with opportunities and experiences to grow in my profession.</p>
                </div>
              </ContentArea>
            </section>
            <section id="rich-bradley-story" className="sixty-section">
              <ContentArea size='xl'>
                <div className="flex w-full flex-wrap sixty-title-row items-end">
                  <h2 className="mx-4 mt-4 mb-0 sixty-title">Rich Bradley</h2>
                  <div className="ml-0 ml-sm-auto">
                    <Img
                      className="flex mt-4 mx-4 mb-0 rounded"
                      fixed={props.data.rich_bradley.childImageSharp.fixed}
                      alt="Rich Bradley"
                    />
                  </div>
                </div>
                <hr className="mx-4" />
                <div className="px-4 py-0">
                  <p>My career in biology was launched in high school, thanks to a wonderful teacher Mr. Phil Littell. I was already very much an outdoor kid. I spent all my free time exploring the fields, hills, cliffsides, and tide pools looking for and watching insects, snakes, lizards, and other animals near our home on the Palos Verdes Peninsula, California. I was a keen birdwatcher by the time I was 13 years old, a pretty rare thing in the mid-1960s.</p>

                  <p>When I started high school my interest in nature was general, unfocused. My first biology course was the BSCS biology course (Biology I) from Mr. Phil Littell in my freshman year (1964-65) and only earned a B. I suppose at that time I was not a great test taker. The course helped me develop a scientific approach to the world. By the time I took the Biology II advanced course in my sophomore year (also from Mr. Littell), I had begun to conduct more detailed observations as well as experiments.</p>

                  <p>I also served as the behind-the-scenes "science assistant" for the first period during my sophomore and junior years. This job provided me with a wide-ranging background in preparation of laboratory exercises for chemistry and biology classes, maintenance of living animals and plants for class use, and even some field work. I remember collecting sea urchins for the development/embryology labs. My brother and I collected two different common species (the purple urchin, Strongylocentrotus purpuratus, and the giant urchin S. franciscanus.). We collected them on the weekend, and when we brought them back home, they needed a lot of water and an aquarium pump to keep them vigorous. We used a small plastic "kiddie pool," and we had to lug many heavy buckets of seawater up the steep dirt cliffside trails near our home several times. On Monday we would bring them into the biology lab. In class, these were stimulated to release their eggs and sperm with injections of potassium chloride. It was a great success, we were able to demonstrate fertilization and early embryonic growth (visible under the microscope) through the gastrula stage. I remember how excited everyone was (including Mr. Littell) when this worked like a charm. I continued as the science department's chief "lab assistant" during my senior year.</p>

                  <p>These experiences provided me with a solid science-based worldview. Eventually, I earned both a master's and Ph.D. in biology, and I just retired from a 30-year career in university teaching. I've published two books and 28 scientific articles, and it all began with BSCS high school biology.</p>
                </div>
              </ContentArea>
            </section>
            <section id="ed-drexler-story-1" className="sixty-section-alt">
              <ContentArea size='xl'>
                <div className="flex w-full flex-wrap sixty-title-row items-end">
                  <h2 className="mx-4 mt-4 mb-0 sixty-title">Ed Drexler – Story 1</h2>
                  <div className="ml-0 ml-sm-auto">
                    <Img
                      className="flex mt-4 mx-4 mb-0 rounded"
                      fixed={props.data.ed_drexler_1.childImageSharp.fixed}
                      alt="Ed Drexler"
                    />
                  </div>
                </div>
                <hr className="mx-4 sixty-hr-bscs-blue" />

                <div className="px-4 py-2 sixty-content-alt">
                  <p>One of the directors of BSCS who I worked with, was a man named Joe McInerney. He came up with an idea that we should concentrate some of our materials on human genetics. During the decade of the ‘80s, I was part of that writing team. This team was composed of two pediatricians and three biology teachers. We focused on several modules dealing with the genetics of biology. At appropriate times, I used the modules as a part of my course of study.</p>

                  <p>In the early ‘90s, I developed a one-semester course with the emphasis on human genetics, using the modules.</p>

                  <p>In the early 2000s, I received a letter from a former student who had been enrolled in my first class in human genetics. She told me that her college advisor had noticed she had taken a course in human genetics in high school. As a result of this information, her advisor suggested to her that she look into becoming a student laboratory assistant. She continued to focus on the biological sciences. She graduated with a major in biology. In her letter, she thanked me for encouraging her interests in biological sciences. She emphasized the human genetics course that had been offered in high school. It played a key part in directing her course of study in college.</p>
                </div>
              </ContentArea>
            </section>
            <section id="ed-drexler-story-2" className="sixty-section">
              <ContentArea size='xl'>
                <div className="flex w-full flex-wrap sixty-title-row items-end">
                  <h2 className="mx-4 mt-4 mb-0 sixty-title">Ed Drexler – Story 2</h2>
                  <div className="ml-0 ml-sm-auto">
                    <Img
                      className="flex mt-4 mx-4 mb-0 rounded"
                      fixed={props.data.ed_drexler_2.childImageSharp.fixed}
                      alt="Rich Bradley with people"
                    />
                  </div>
                </div>
                <hr className="mx-4" />
                <div className="px-4 py-0">
                  <p>In my first 10 years at Pius XI Catholic High School in Milwaukee, I felt I was not using the most appropriate methods of teaching biology.</p>

                  <p>In March of 1961, I attended the annual convention of the National Science Teachers Association in Atlantic City, NJ. A team composed of 13 Milwaukee area biology teachers was chosen to field test one of the three versions, the Yellow version, of BSCS. We met every Monday evening for a semester. We reviewed the past week's experiences in the classroom and discussed what we would present the next week.</p>

                  <p>I was convinced this was the way to go. I loved the idea of teaching students science from an inquisitive point of view, as opposed to lecturing them on supposed "facts."</p>

                  <p>Before BSCS, biology was based on a rhetoric of conclusions. Everything was "known," and I was simply teaching things there were "known." With BSCS, there were a lot of unknowns that we could look at. The kids left the class knowing about biology but having a lot of questions. I hoped they were interested in those questions and kept on with the subject material. It was not a finished thing; it was an ongoing thing, literally, for the rest of their lives.</p>

                  <p>It's hard to describe. You'd have to experience it to appreciate that it was a much better way of teaching. I would never have taught that way if I weren't getting a lot of direction from the people in Boulder and experiencing new things all the time.</p>

                  <p>I remained active with BSCS for the rest of the 1960s, and for the next 5 decades. I spent the summers in Boulder helping to write and revise a variety of materials, returning each fall with renewed enthusiasm. I participated in a BSCS final study as recently as 2010.</p>

                  <p>I retired after teaching and using the many unique methods, applications and laboratory activities found in the BSCS materials.</p>
                </div>
              </ContentArea>
            </section>
            <section id="tod-fairbanks-story" className="sixty-section-alt">
              <ContentArea size='xl'>
                <div className="flex w-full flex-wrap sixty-title-row items-end">
                  <h2 className="mx-4 mt-4 mb-0 sixty-title">Tod Fairbanks</h2>
                </div>
                <hr className="mx-4 sixty-hr-bscs-blue" />
                <div className="px-4 py-2 sixty-content-alt">
                  <p>Back when I was sophomore in high school my teacher noticed me lingering around after class and asked me if I wanted to be his lab assistant. I said yes and held that position until I graduated in 1965.</p>

                  <p>My teacher was Charles Butterfield, whom I believe still lives in southern New Hampshire but who taught at Brattleboro Union High School in Vermont. During my tenure as his lab assistant, I was able to complete my first teaching gig as Mr. Butterfield's assistant when he was a weekend teacher for BSCS at Wellesley College. My role was to teach teachers how to raise planaria. I think it turned out fairly well since I have a B.S. (medical technology), M.S. (botany-cell biology), M.Ed. (foundations of education), and Ph.D. (immunology).</p>

                  <p>The BSCS Green version seems to have been a good start for me in my education, my career in medical laboratory science, as an Associate Professor at the University of Illinois, to working the diagnostics and pharmaceutical industries from which I retired only to take up teaching biology again at my current institution, Palm Beach State College.</p>

                  <p>I have included two scans, one of the Green Version covers that I doodled on and one might recognize some algae. The other is an algal plate from inside the paperback version of the book. I believe I have a copy of the textbook because our school went to a hardcover or newer version that was issued when I graduated from high school, and my teacher gave a copy to keep.</p>
                </div>
              </ContentArea>
            </section>
            <section id="sherry-s-herron-story" className="sixty-section">
              <ContentArea size='xl'>
                <div className="flex w-full flex-wrap sixty-title-row items-end">
                  <h2 className="mx-4 mt-4 mb-0 sixty-title">Sherry S. Herron</h2>
                </div>
                <hr className="mx-4" />
                <div className="px-4 py-0">
                  <p>I felt like an astronaut being selected to walk on the moon when I became a 'member of the herd' in 2000. Hired to assist in the writing of 'Bioinformatics and The Human Genome Project' with Mark Bloom, I was able to participate in many projects and make field test visits to schools in many states. Attending the Project 2061 Science Textbook Conference sponsored by AAAS in D.C., 2001, with Rodger Bybee and Pam Scotter was a particular highlight. The two years I spent at BSCS were nothing short of transformational for my family and me. My colleagues were amused at how excited I was to work at BSCS.</p>

                  <p>I continue to require my biology licensure students to purchase the Ecological Approach. Most (if not any) public schools in Mississippi do not provide textbooks, but their teachers need access to the best resources available. I will retire next summer as associate professor and director of the Center for Science and Mathematics Education at the University of Southern Mississippi. I don't know what my successor will use. A new edition, please!!!!</p>
                </div>
              </ContentArea>
            </section>
            <section id="jane-butler-kahle-story" className="sixty-section-alt">
              <ContentArea size='xl'>
                <div className="flex w-full flex-wrap sixty-title-row items-end">
                  <h2 className="mx-4 mt-4 mb-0 sixty-title">Jane Butler Kahle</h2>
                  <div className="ml-0 ml-sm-auto">
                    <Img
                      className="flex mt-4 mx-4 mb-0 rounded"
                      fixed={props.data.jane_butler_kahle.childImageSharp.fixed}
                      alt="Jane Butler Kahle"
                    />
                  </div>
                </div>
                <hr className="mx-4 sixty-hr-bscs-blue" />
                <div className="px-4 py-2 sixty-content-alt">
                  <p>BSCS and my career have been intertwined since 1956, when I began teaching high school biology in Logansport, IN. During my interview, I mentioned that I wanted to use one of the BSCS texts; the superintendent replied, "Which one—the Red Version?" I knew I had it made; he had absolutely no idea about the BSCS texts, and I could order whichever version I wanted! Because I focused on microbiology as an undergraduate at Wellesley College, the Blue Version (Biological Science: Molecules to Man) was an obvious choice. (Although today I cringe that the title was not "to Humans"!)</p>

                  <p>I taught the Blue Version and the second course in biology, Biological Science: Interaction of Experiments and Ideas, in rural Indiana schools for 10 years, concurrently earning an MS degree at Purdue University. I was privileged to be part of the National Science Foundation (NSF) supported Biology Teacher Institute at Purdue. However, when I began, I was told that I would not receive support, because I was a woman with children, who obviously would not continue teaching! In spite of that comment (and I was supported during my last two years), it was a great experience and heavily focused on the BSCS curricula. Both Ben Olson and Joe Novak were instrumental in introducing BSCS to the teachers in the Institute.</p>

                  <p>I returned to high school teaching but had my sights set on another goal. I enrolled as a doctoral student at Purdue with Sam Postlethwaite as my major professor. Sam had developed a very innovative approach to teaching college biology, which mirrored the strategies of BSCS, especially its second course. I received my Ph.D. in 1972 and went on to teach Methods of Biology Teaching at Purdue. Needless to say, my course emphasized the BSCS texts and strategies. I also mentored and served as major professor for 17 doctoral students, one of whom, April Gardner, joined BSCS in the mid-1980s.</p>

                  <p>My next active participation with BSCS was when I was appointed to its Board of Directors in 1983. I served on the Board, on the Executive Committee, and as Board President until 1989. And, I loved every minute of it. It was an exciting time for biology education. Texas' Board of Education had approved requiring Creationism to be taught in biology classes and had declined to include biology texts that only taught evolution (e.g., the BSCS texts) on its approved textbook list. In addition, BSCS moved from the campus of the University of Colorado in Boulder to the campus of Colorado College in Colorado Springs. Under the excellent leadership of Jack Carter, the move went very smoothly. As I said, I enjoyed every minute on the Board and was so pleased to work with Jack, Joe McInerney, and Rodger Bybee, all excellent leaders, who contributed much not only to BSCS but to science education.</p>

                  <p>In 1989, I accepted an Endowed Professorship at Miami University. I was so honored to meet and work with Paul Pearson, President, who was one of the authors of the original Green Version. So, my story begins as a biology teacher and ends as a professor, and every step of the way BSCS has been part of my story. Congratulations to everyone on Staff and on the Board during this 60th anniversary year. It is a wonderful institution!</p>
                </div>
              </ContentArea>
            </section>
            <section id="nancy-kellogg-story" className="sixty-section">
              <ContentArea size='xl'>
                <div className="flex w-full flex-wrap sixty-title-row items-end">
                  <h2 className="mx-4 mt-4 mb-0 sixty-title">Nancy Kellogg</h2>
                  <div className="ml-0 ml-sm-auto">
                    <Img
                      className="flex mt-4 mx-4 mb-0 rounded"
                      fixed={props.data.nancy_kellogg.childImageSharp.fixed}
                      alt="Nancy Kellogg"
                    />
                  </div>
                </div>
                <hr className="mx-4" />
                <div className="px-4 py-0">
                  <p>I've known and interacted with BSCS employees starting around 1968. Since my degrees are in biology and science education, I was always looking for new resources to use with my students during the early part of my career as a high school biology teacher. During my student teaching, I taught students using the BSCS Yellow version. When I taught at Mitchell High School starting in 1968 in Colorado Springs, the BSCS Green Version was our major curriculum. I also used BSCS Patterns and Processes with some of my students as the main resource.</p>

                  <p>When the BSCS Human Sciences Program was being developed, I worked with Dr. James Robinson as a consultant on assessment. Unfortunately, this great curriculum never was completed due to major funding cuts at NSF. The Human Sciences Program was very innovative and developmentally appropriate for students at the middle level. The program was ahead of its time! I hoped this program would be completed at a later time, but that never happened.</p>

                  <p>During the 1990s, I was a Co-PI on the NSF funded Colorado Statewide Systemic Initiative for Mathematics and Science. We called our initiative CONNECT. BSCS was one of our many collaborators on the grant. During this time, I served on several BSCS Advisory Committees. I can't remember the titles for the various committees. However, one of these committees was on professional development at the time BSCS started that work.</p>

                  <p>From 1998 to 2018, I have been the Chair or on the Steering Committee for the Colorado Science Education Network (CSEN). This network serves science education leaders throughout Colorado and is dedicated to providing current science education information and professional development for state leaders. BSCS is actively engaged with CSEN and has provided meeting space and professional development on a variety of topics over the years. For example, BSCS led a session on Advocating for Science last year for CSEN leaders. The BSCS facilitators had the group explore advocacy resources to support three-dimensional, phenomenon-focused science programs based on the K-12 Framework for Science Education.</p>

                  <p>BSCS has been an important part of my professional career.</p>

                  <p>I congratulate BSCS on their 60th anniversary and their on-going exemplary work with science educators.</p>
                </div>
                </ContentArea>
            </section>
            <section id="jane-larson-story" className="sixty-section-alt">
              <ContentArea size='xl'>
                <div className="flex w-full flex-wrap sixty-title-row items-end">
                  <h2 className="mx-4 mt-4 mb-0 sixty-title">Jane Larson</h2>
                </div>
                <hr className="mx-4 sixty-hr-bscs-blue" />

                <div className="px-4 py-2 sixty-content-alt">
                  <p>In 1986, as a newly arrived biology teacher at Kubasaki High School, a Department of Defense Dependents School in Okinawa, Japan, I discovered a compact text entitled Biological Science: The Interaction of Experiments and Ideas (BSCS Second Course) in a dusty corner of the science supply room.</p>

                  <p>A class set was resurrected, and my students and I began to delve into statistics that supported explicit guidance on the construction of research projects. For the first time, the kids saw the close relationship between math and science as their original investigations developed into legitimate research. The highlight of our year was the submission of their research projects to the Junior Science and Humanities Symposium held in Tsukuba City, Japan. Presenting their investigations in a public forum was the culmination of their experiences as science practitioners, lessons that hopefully have transformed the ways they appreciate and understand science. Students in succeeding years continued this course of study supported by "BSCS Black."</p>
                </div>
              </ContentArea>
            </section>
            <section id="harold-pratt-story" className="sixty-section">
              <ContentArea size='xl'>
                <div className="flex w-full flex-wrap sixty-title-row items-end">
                  <h2 className="mx-4 mt-4 mb-0 sixty-title">Harold Pratt</h2>
                  <div className="ml-0 ml-sm-auto">
                    <Img
                      className="flex mt-4 mx-4 mb-0 rounded"
                      fixed={props.data.harold_pratt.childImageSharp.fixed}
                      alt="Harold Pratt"
                    />
                  </div>
                </div>
                <hr className="mx-4" />
                <div className="px-4 py-0">
                  <p>It was the fall of 1963, and I had just been appointed Science Supervisor of the Jefferson County (CO) Public Schools (Jeffco) to discover that the district was a pilot center for the BSCS Green Version Biology, an innovative NSF funded high school biology program. This was the beginning of a 55- year relationship with BSCS. It began a long, productive, and mutually beneficial relationship but mostly it was my "go to" source of professional support, quality instructional, and professional development.</p>

                  <p>The following year I arranged for two groups of Jeffco junior high teachers to pilot two new NSF funded programs, Introductory Physical Science (IPS) and Earth Science Curriculum Project (ESCP). This left the life science portion of our junior high curriculum needing a similar innovative inquiry program. So, I drove to Boulder and introduced myself and our need to Bill Mayer, BSCS Executive Director and suggested that they initiate a proposal for junior high life science program. The response as I recall was that they were considering a proposal for a human sciences program. That was several years away, so we proceeded to submit our own successful proposal to NSF for what become to be Middle School Life Science still published by Kendall Hunt. The BSCS idea soon gained stature and funding from NSF, and in 1968 I participated in two writing conferences for the future BSCS Human Sciences Program. Teachers in Jeffco provided pilot teaching of material from the project as part of the development project. About the same time, the district participated in the pilot development of an Environmental Module being developed by BSCS.</p>

                  <p>In 1977 I filled a vacancy on the BSCS Board of Director and the following year was elected to a four-year term on the board. In 1986 when IBM provided funding for a preliminary study the feasibility of developing inquiry-oriented elementary science instructional materials I joined the advisory committee for the project followed a year later by membership on a similar advisory committee for the four-year project that developed Science for Life and Living.</p>

                  <p>After retiring from Jeffco in 1991 and working as Senior Program Officer at the National Research Council (NRC) during the development of the National Science Education Standards (NSES) from 1992 to 1994, Rodger Bybee, BSCS Assistant Executive Director who had co-directed the work on the Standards, invited me to come to BSCS and direct the revision of Science for Life and Living to bring it into alignment with the NSES. This work, which reformatted the materials into a modular design resulted in the current product Science Tracks.</p>

                  <p>After almost two years at BSCS, another three years at the NRC, and three years in the presidential chain at NSTA, I was invited by Lauren Resnick to join the Institute for Learning at the University of Pittsburg and later a director of the science team for Project SCALE, a multi-million-dollar NSF professional development project working with four major school districts.</p>

                  <p>When the size of the project called for additional highly qualified staff, we called on the BSCS professional development staff to assist us. To no one's surprise, this produced the personnel needed for the success of the five-year project.</p>

                  <p>So much for the 55 years of our relationship; it has mellowed but continued in my almost complete retirement to an occasional professional learning experience that BSCS often generously provides for the science specialists in Colorado – a reminder of the history and superb quality of the organization.</p>
                </div>
              </ContentArea>
            </section>
            <section id="donna-satterthwait-story" className="sixty-section-alt">
              <ContentArea size='xl'>
               <div className="flex w-full flex-wrap sixty-title-row items-end">
                  <h2 className="mx-4 mt-4 mb-0 sixty-title">Donna Satterthwait</h2>
                  <div className="ml-0 ml-sm-auto">
                    <Img
                      className="flex mt-4 mx-4 mb-0 rounded"
                      fixed={props.data.donna_satterthwait.childImageSharp.fixed}
                      alt="Donna Satterthwait"
                    />
                  </div>
                </div>
                <hr className="mx-4 sixty-hr-bscs-blue" />
                <div className="px-4 py-2 sixty-content-alt">
                  <p>As a sophomore at Hoover High School in Fresno, California, I was introduced to the BSCS way. Mr. Garland Johnson, my teacher, was one of the early adopters of the yellow version of the BSCS biology textbook. We had great lessons, filled with the activities and investigations outlined in the student lab manual. I'm certain that my love of biological knowledge stemmed from those experiences, which I can fondly recall. From measuring the curvature of Avena apical tips to inoculating baby chicks with testosterone (I know schools would not be able to do these things now), I loved it. I'll never forget the incredible sight of chick embryos each day in their development; I was quite good at opening the eggshell while keeping the membranes intact.</p>

                  <p>My junior year studies consisted of physics, rather than chemistry because I wanted to enroll in the BSCS Advanced Biology Course and that was the only way I could fit it into my timetable. I put off doing chemistry, typically studied in 11th grade, until year 12. The Advanced Biology course served as an introduction to the philosophy and nature of science and its relationship with published literature. After graduating from high school, my only question was, was I going to major in botany or zoology at UCLA.</p>

                  <p>I found I had gained a solid understanding of the biological sciences from my high school studies. I went on to complete an undergraduate degree in botany and a Ph.D. in biology. Fifty-five years later, I still am involved in biological sciences by educating graduate students to become accredited teachers of secondary science.</p>
                </div>
              </ContentArea>
            </section>
            <section id="eugenie-scott-story" className="sixty-section">
              <ContentArea size='xl'>
                <div className="flex w-full flex-wrap sixty-title-row items-end">
                  <h2 className="mx-4 mt-4 mb-0 sixty-title">Eugenie Scott</h2>
                  <div className="ml-0 ml-sm-auto">
                    <Img
                      className="flex mt-4 mx-4 mb-0 rounded"
                      fixed={props.data.eugenie_scott.childImageSharp.fixed}
                      alt="Eugenie Scott"
                    />
                  </div>
                </div>
                <hr className="mx-4" />
                <div className="px-4 py-0">
                  <p>BSCS has not only been around for 60 years – longevity isn't sufficient – but has made a profound difference in science education. The organization prided itself on being a little beyond the cutting edge – with being far enough "out there" that their instructional resources stretched the teachers and their students. This, of course, had consequences for sales: you sell many more books if you meet the lowest (or at least a low) common denominator. But BSCS stuck to its principles and kept nudging science education closer to the inquiry learning model it pioneered. Its influence can be seen both in the National Science Education Standards (1994!) as well as NGSS. BSCS's approach to science education is the one that other producers of instructional materials and teacher development eventually emulate.</p>

                  <p>I played a very small role in BSCS's history, but I'm proud to have been associated with it in even a small way. I learned so much from my fellow board members, who always knew a lot more than I did about science education. I still have the 40th-anniversary t-shirt, btw! And I wear it with pride!</p>
                </div>
              </ContentArea>
            </section>
            <section id="susan-udin-story" className="sixty-section-alt end rounded-bottom">
              <ContentArea size='xl'>
                <div className="flex w-full flex-wrap sixty-title-row items-end">
                  <h2 className="mx-4 mt-4 mb-0 sixty-title">Susan Udin</h2>
                  <div className="ml-0 ml-sm-auto">
                    <Img
                      className="flex mt-4 mx-4 rounded"
                      fixed={props.data.susan_udin.childImageSharp.fixed}
                      alt="Susan Udin"
                    />
                  </div>
                </div>
                <hr className="sixty-hr-bscs-blue mx-4" />
                <div className="px-4 py-2">
                  <p>The BSCS Blue version certainly changed my life. I'd always been interested in biological questions. By the time I was 4 or 5, I was wondering about how the brain could produce imagined visual images and how bilateral symmetry came about during development. However, I really didn't connect these kinds of questions with what I'd been exposed to in school as "biology," which was primarily taxonomy and memorization. Luckily for me, in 10th grade (1962-63), my school switched to the BSCS curriculum in place of the traditional curriculum. I will always remember the day that our teacher described the Miller-Urey experiments on the origin of life. That did it. From that day on, I knew that I'd become a biologist. It took a few years before the details were sorted out, but I became a developmental neuroscientist. I wonder what would have happened without that course.</p>
                </div>
              </ContentArea>
            </section>
          </div>
        </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    sixty_years_of_stories: file(relativePath: { eq: "60-years-of-stories/60_years_of_stories.jpg" }) {
      ...rowImage
    }
    james_barufaldi: file(relativePath: { eq: "60-years-of-stories/james_barufaldi.jpeg" }) {
      childImageSharp {
        fixed(height: 124) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    rich_bradley: file(relativePath: { eq: "60-years-of-stories/rich_bradley.jpg" }) {
      childImageSharp {
        fixed(height: 124) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    ed_drexler_1: file(relativePath: { eq: "60-years-of-stories/ed_drexler_1.jpg" }) {
      childImageSharp {
        fixed(height: 124) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    ed_drexler_2: file(relativePath: { eq: "60-years-of-stories/ed_drexler_2.jpg" }) {
      childImageSharp {
        fixed(height: 124) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    jane_butler_kahle: file(relativePath: { eq: "60-years-of-stories/jane_butler_kahle.jpg" }) {
      childImageSharp {
        fixed(height: 124) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    nancy_kellogg: file(relativePath: { eq: "60-years-of-stories/nancy_kellogg.jpg" }) {
      childImageSharp {
        fixed(height: 124) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    harold_pratt: file(relativePath: { eq: "60-years-of-stories/harold_pratt.jpg" }) {
      childImageSharp {
        fixed(height: 124) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    donna_satterthwait: file(relativePath: { eq: "60-years-of-stories/donna_satterthwait.jpg" }) {
      childImageSharp {
        fixed(height: 124) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    donna_satterthwait: file(relativePath: { eq: "60-years-of-stories/donna_satterthwait.jpg" }) {
      childImageSharp {
        fixed(height: 124) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    eugenie_scott: file(relativePath: { eq: "60-years-of-stories/eugenie_scott.jpg" }) {
      childImageSharp {
        fixed(height: 124) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    susan_udin: file(relativePath: { eq: "60-years-of-stories/susan_udin.jpg" }) {
      childImageSharp {
        fixed(height: 124) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

export default SixtiethAnniversaryPage
